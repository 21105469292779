import { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { getFirestore, collection, doc, getDoc, getDocs, query, orderBy, limit } from 'firebase/firestore';
// import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { getStorage } from 'firebase/storage';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { useAtom } from 'jotai';
import { Layout } from 'antd';

import Header from './Header';
import Content from './Content';
import Footer from './Footer';
import { firebaseConfig } from './config';
import {
  userAtom,
  productCategoriesAtom,
  carouselItemsAtom,
  linksAtom,
  recentNewsAtom,
  countsAtom,
  configAtom,
  contactInfoAtom,
  socialsAtom,
} from './atoms';

import 'antd/dist/antd.css';
import 'react-quill/dist/quill.snow.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import './App.css';

const initFirebase = () => {
  const app = initializeApp(firebaseConfig);

  // const appCheck = initializeAppCheck(app, {
  //   provider: new ReCaptchaV3Provider('6Le0wmokAAAAAAgBG2MTkffux97q5Il0TsB6otDb'),
  //   isTokenAutoRefreshEnabled: true,
  // });
  // if (process.env.NODE_ENV !== 'production') console.log(appCheck);

  const analytics = getAnalytics(app);
  const storage = getStorage(app);
  const db = getFirestore(app);
  const auth = getAuth(app);

  return { app, analytics, storage, db, auth };
};

const { analytics, storage, db } = initFirebase();

function App() {
  const [user] = useAtom(userAtom);
  const [productCategories, setProductCategories] = useAtom(productCategoriesAtom);
  const [carouselItems, setCarouselItems] = useAtom(carouselItemsAtom);
  const [links, setLinks] = useAtom(linksAtom);
  const [recentNews, setRecentNews] = useAtom(recentNewsAtom);
  const [counts, setCounts] = useAtom(countsAtom);
  const [config, setConfig] = useAtom(configAtom);
  const [contactInfo, setContactInfo] = useAtom(contactInfoAtom);
  const [socials, setSocials] = useAtom(socialsAtom);

  const isContentLoaded = !!(
    productCategories?.length &&
    carouselItems?.length &&
    recentNews?.length &&
    counts &&
    config
  );
  const isFooterLoaded = !!(links?.length && contactInfo && socials);

  useEffect(() => {
    logEvent(analytics, 'app_loaded');
  }, []);

  // fetch product categories
  useEffect(() => {
    if (user?.uid && !productCategories?.length) {
      getDocs(query(collection(db, 'productCategories'), orderBy('index')))
        .then((querySnapshot) => {
          const categories = [];
          querySnapshot.forEach((doc) => categories.push(doc.data()));
          setProductCategories(categories);
        })
        .catch(() =>
          logEvent(analytics, 'db_fetch_error', {
            location: 'productCategories',
          })
        );
    }
  }, [user, productCategories, setProductCategories]);

  // fetch carousel items
  useEffect(() => {
    if (user?.uid && !carouselItems?.length) {
      getDocs(query(collection(db, 'carouselItems'), orderBy('index')))
        .then((querySnapshot) => {
          const items = [];
          querySnapshot.forEach((doc) => items.push(doc.data()));
          setCarouselItems(items);
        })
        .catch(() => logEvent(analytics, 'db_fetch_error', { location: 'carouselItems' }));
    }
  }, [user, carouselItems, setCarouselItems]);

  // fetch links
  useEffect(() => {
    if (user?.uid && !links?.length) {
      getDocs(query(collection(db, 'links'), orderBy('index')))
        .then((querySnapshot) => {
          const items = [];
          querySnapshot.forEach((doc) => items.push(doc.data()));
          setLinks(items);
        })
        .catch(() => logEvent(analytics, 'db_fetch_error', { location: 'links' }));
    }
  }, [user, links, setLinks]);

  // fetch last 3 blog items
  useEffect(() => {
    if (user?.uid && !recentNews?.length) {
      getDocs(query(collection(db, 'blog'), orderBy('time', 'desc'), limit(3)))
        .then((querySnapshot) => {
          const news = [];
          querySnapshot.forEach((doc) => news.push(doc.data()));
          setRecentNews(news);
        })
        .catch(() => logEvent(analytics, 'db_fetch_error', { location: 'blog' }));
    }
  }, [user, recentNews, setRecentNews]);

  // fetch counts
  useEffect(() => {
    if (user?.uid && !counts) {
      getDoc(doc(db, 'meta', 'counts'))
        .then((doc) => {
          if (doc.exists()) setCounts(doc.data());
        })
        .catch(() => logEvent(analytics, 'db_fetch_error', { location: 'counts' }));
    }
  }, [user, counts, setCounts]);

  // fetch config
  useEffect(() => {
    if (user?.uid && !config) {
      getDoc(doc(db, 'meta', 'config'))
        .then((doc) => {
          if (doc.exists()) setConfig(doc.data());
        })
        .catch(() => logEvent(analytics, 'db_fetch_error', { location: 'config' }));
    }
  }, [user, config, setConfig]);

  // fetch contact info
  useEffect(() => {
    if (user?.uid && !contactInfo) {
      getDoc(doc(db, 'contact', 'contactInfo'))
        .then((doc) => {
          if (doc.exists()) setContactInfo(doc.data());
        })
        .catch(() => logEvent(analytics, 'db_fetch_error', { location: 'contactInfo' }));
    }
  }, [user, contactInfo, setContactInfo]);

  // fetch socials
  useEffect(() => {
    if (user?.uid && !socials) {
      getDoc(doc(db, 'contact', 'socials'))
        .then((doc) => {
          if (doc.exists()) setSocials(doc.data());
        })
        .catch(() => logEvent(analytics, 'db_fetch_error', { location: 'socials' }));
    }
  }, [user, socials, setSocials]);

  return (
    <div className="app">
      <Router>
        <Layout>
          <Header db={db} />
          <Content db={db} storage={storage} isLoaded={isContentLoaded} />
          <Footer db={db} isLoaded={isFooterLoaded} />
        </Layout>
      </Router>
    </div>
  );
}

export default App;
