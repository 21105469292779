import { useState, useEffect, useCallback } from 'react';
import { Affix, Grid, Layout, Menu, Button, Input, Drawer } from 'antd';
import { useNavigate, useLocation, createSearchParams } from 'react-router-dom';
import { MdMenu, MdSearch } from 'react-icons/md';
import { useAtom } from 'jotai';

import { urlFormat } from './util';
import { STORAGE_URL, LOGO_URL, LOGO_CUT_URL } from './constants';
import { isMenuStickingAtom, productCategoriesAtom, configAtom } from './atoms';

import './Header.css';

function Header() {
  const location = useLocation();
  const navigate = useNavigate();
  const screen = Grid.useBreakpoint();

  const parseMenuFromLocation = useCallback(() => {
    if (location.pathname === '/') return ['home'];
    if (location.pathname.includes('/products')) return ['products'];
    if (location.pathname === '/pg-lessons') return ['pg-lessons'];
    if (location.pathname === '/hg-lessons') return ['hg-lessons'];
    if (location.pathname === '/calendar') return ['calendar'];
    if (location.pathname.includes('/blog')) return ['blog'];
    if (!screen.lg && location.pathname.includes('/search')) return ['search'];
    return [];
  }, [location.pathname, screen.lg]);

  const [openMenus, setOpenMenus] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(parseMenuFromLocation(location));
  const [isMenuSticking, setIsMenuSticking] = useAtom(isMenuStickingAtom);
  const [productCategories] = useAtom(productCategoriesAtom);
  const [config] = useAtom(configAtom);

  useEffect(() => {
    setSelectedMenu(parseMenuFromLocation());
    setIsSearchActive(false);
  }, [parseMenuFromLocation]);

  useEffect(() => {
    setIsDrawerOpen(false);
  }, [screen.lg]);

  const navigateTo = (url) => {
    navigate(url);
    window.scrollTo({ left: 0, top: 0 });
  };

  const closeMenus = () => setTimeout(() => setOpenMenus([]), 100);

  const selectMenuItem = (id) => {
    setSelectedMenu([id || 'home']);
    closeMenus();
    setOpenMenus([]);
    setIsDrawerOpen(false);
    navigateTo(`/${id || ''}`);
  };

  const selectProductMenuItem = (id) => {
    setSelectedMenu(['products']);
    closeMenus();
    setOpenMenus([]);
    setIsDrawerOpen(false);
    navigateTo(`/products/${id}`);
  };

  const selectProductSubMenuItem = (id, subid) => {
    setSelectedMenu(['products']);
    closeMenus();
    setOpenMenus([]);
    setIsDrawerOpen(false);
    navigateTo(`/products/${id}/${subid}`);
  };

  const onMenuOpenChange = (open) => setOpenMenus(open);

  const generateProductMenu = () => {
    const categories = productCategories?.map((p) => {
      if (!p.subCategories)
        return {
          key: p.name,
          label: p.name,
          onClick: () => selectProductMenuItem(urlFormat(p.shortName || p.name)),
        };

      return {
        key: p.name,
        label: p.name,
        popupOffset: [0, 0],
        onTitleClick: () => openMenus.includes(p.name) && selectProductMenuItem(urlFormat(p.shortName || p.name)),
        children: p.subCategories?.map((s) => ({
          key: s,
          label: s,
          onClick: () => selectProductSubMenuItem(urlFormat(p.shortName || p.name), urlFormat(s)),
        })),
      };
    });
    return [...(categories || []), { key: 'sale', label: 'On Sale', onClick: () => selectProductMenuItem('sale') }];
  };

  const onSearchClick = () => {
    if (!isSearchActive) {
      setIsSearchActive(true);
    } else if (searchInput?.length > 2) {
      navigateTo({
        pathname: '/search',
        search: `?${createSearchParams({ s: searchInput })}`,
      });
    } else if (!searchInput) {
      setIsSearchActive(false);
    }
  };

  const onDrawerSearchClick = () => {
    if (searchInput?.length > 2) {
      navigateTo({
        pathname: '/search',
        search: `?${createSearchParams({ s: searchInput })}`,
      });
      setIsDrawerOpen(false);
    }
  };

  function SearchBar() {
    return (
      <div className="search-bar">
        <Input
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          onKeyUp={(e) => {
            if (e.key === 'Enter' || e.keyCode === 13) onSearchClick();
          }}
          style={{ width: isSearchActive ? '200px' : '0', opacity: isSearchActive ? '1' : '0' }}
        />
        <Button className="search-bar-button" onClick={onSearchClick}>
          <MdSearch />
        </Button>
      </div>
    );
  }

  const items = [
    { key: 'home', label: 'Home', onClick: () => selectMenuItem(null) },
    {
      key: 'products',
      label: 'Products',
      popupOffset: [0, 0],
      onTitleClick: () => openMenus.includes('products') && selectMenuItem('products'),
      children: generateProductMenu(),
    },
    { key: 'pg-lessons', label: 'Paragliding Lessons', onClick: () => selectMenuItem('pg-lessons') },
    { key: 'hg-lessons', label: 'Hang Gliding Lessons', onClick: () => selectMenuItem('hg-lessons') },
  ];
  if (config?.showCalendar)
    items.push({ key: 'calendar', label: 'Muller Calendar', onClick: () => selectMenuItem('calendar') });
  items.push({ key: 'blog', label: 'News', onClick: () => selectMenuItem('blog') });

  function MainMenu() {
    return (
      <>
        <Menu
          theme="dark"
          mode="horizontal"
          triggerSubMenuAction="click"
          openKeys={openMenus}
          selectedKeys={selectedMenu}
          onOpenChange={onMenuOpenChange}
          items={items}
        />
        {isMenuSticking && screen.lg && SearchBar()}
      </>
    );
  }

  function DrawerMenu() {
    return (
      <Menu
        theme="dark"
        mode="inline"
        openKeys={openMenus}
        selectedKeys={selectedMenu}
        onOpenChange={onMenuOpenChange}
        items={items}
      />
    );
  }

  function DrawerSearch() {
    return (
      <div className="drawer-search">
        <Input
          placeholder="Search"
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          onKeyUp={(e) => {
            if (e.key === 'Enter' || e.keyCode === 13) onDrawerSearchClick();
          }}
        />
        <Button className="search-bar-button" onClick={onDrawerSearchClick}>
          <MdSearch />
        </Button>
      </div>
    );
  }

  return (
    <Layout.Header>
      <div className="header-rows">
        {screen.lg && (
          <div className="header-row top-bar">
            <a href="/">
              <img className="logo" alt="Muller Windsports" src={`${STORAGE_URL}${LOGO_URL}`} />
            </a>
            {!isMenuSticking && SearchBar()}
          </div>
        )}
        <div className={`header-row menu-bar${isMenuSticking ? ' spaced' : ''}`}>
          <Affix offsetTop={0} onChange={setIsMenuSticking}>
            <div className={`menu${isMenuSticking ? ' sticky' : ''}`}>
              {!screen.lg && (
                <img
                  className="logo"
                  alt="Muller Windsports"
                  src={`${STORAGE_URL}${LOGO_URL}`}
                  onClick={() => navigateTo('/')}
                />
              )}
              <div className="menu-container">
                {screen.lg ? (
                  MainMenu()
                ) : (
                  <Button className="burger-button" onClick={() => setIsDrawerOpen(true)}>
                    <MdMenu />
                  </Button>
                )}
              </div>
            </div>
          </Affix>
          {/* {!screen.lg && isMenuSticking && <div className="sticky-spacer" />} */}
        </div>
      </div>
      <Drawer
        width="calc(min(100vw,320px))"
        placement="right"
        open={isDrawerOpen}
        visible={isDrawerOpen}
        title={<img alt="" src={`${STORAGE_URL}${LOGO_CUT_URL}`} />}
        onClose={() => setIsDrawerOpen(false)}
      >
        {DrawerMenu()}
        {DrawerSearch()}
      </Drawer>
    </Layout.Header>
  );
}

export default Header;
