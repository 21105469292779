import { Grid } from 'antd';

import './Calendar.css';

function Calendar() {
  const screen = Grid.useBreakpoint();

  return (
    <div className="calendar">
      <h1 className="page-title">Muller Calendar</h1>
      <div className="content">
        <iframe
          src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FEdmonton&showNav=1&showPrint=0&showTabs=1&showCalendars=0&src=c3VnYWozMXB2YzBrYjZuaHRndW1uMG1uMjhAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%239E69AF"
          style={{ minHeight: screen.lg ? '80vh' : '50vh', minWidth: screen.lg ? '1180px' : '100%' }}
          height="100%"
          title="Muller Calendar"
          frameBorder="0"
          scrolling="no"
        ></iframe>
      </div>
    </div>
  );
}

export default Calendar;
