import { Fragment, useState, useEffect, useCallback } from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Form, Input, Select, Button, Switch, Progress, Space, Collapse, message } from 'antd';
import { ref, uploadBytesResumable, deleteObject } from 'firebase/storage';
import { collection, addDoc, updateDoc, doc } from 'firebase/firestore';
import { useAtom } from 'jotai';
import Quill from 'react-quill';
import { map } from 'lodash';

import { STORAGE_URL } from '../constants';
import { productCategoriesAtom } from '../atoms';
import { urlFormat, nameFromPath } from '../util';
import ProductDisplay from '../Products/ProductDisplay';

import './AddProduct.css';

const formatYoutubeLink = (url) => {
  // TODO other formats
  if (/http(s)+:\/\/youtu.be\/.+/.test(url)) {
    const s = url.split('/');
    return `https://youtube.com/embed/${s[s.length - 1]}`;
  }
  return url;
};

const mapFormToProduct = (values, images, cardImages, photos, imagesToDelete) => {
  const specifications =
    values.specsColumns?.length &&
    values.specsRows?.length &&
    values.specsRows.map((row) => map(row, (a) => a).join(';'));
  const specificationHeaders = specifications?.length && values.specsColumns.map((c) => c?.columnName || '').join(';');
  return {
    name: values.name,
    brand: values.brand,
    category: values.category,
    subCategory: values.subCategory,
    price: values.price,
    sizeNames: values.sizes?.every((s) => s && s.sizeName && s.sizePrice) ? values.sizes.map((s) => s.sizeName) : [],
    sizePrices: values.sizes?.every((s) => s && s.sizeName && s.sizePrice) ? values.sizes.map((s) => s.sizePrice) : [],
    description: values.description,
    subTitle: values.subTitle,
    infoLine: values.infoLine,
    moreInfoLink: values.moreInfoLink,
    videoLink: values.videoLink && formatYoutubeLink(values.videoLink),
    images: images.filter((i) => !imagesToDelete.some((j) => i === j.path)),
    cardImages: cardImages.filter((i) => !imagesToDelete.some((j) => i === j.path)),
    photos: photos.filter((i) => !imagesToDelete.some((j) => i === j.path)),
    features: values.features,
    specifications: specifications || [],
    specificationHeaders: specificationHeaders || '',
    hidden: values.hidden || false,
    onSale: values.onSale || false,
  };
};

const mapProductToForm = (product) => {
  const sizes = product.sizeNames?.map((sizeName, i) => ({
    sizeName,
    sizePrice: product.sizePrices[i],
  }));
  const specsColumns = product.specificationHeaders?.split(';').map((h) => ({
    columnName: h,
  }));
  const specsRows = product.specifications?.map((s) => {
    const values = s?.split(';');
    const result = {};
    values?.forEach((v, i) => {
      result[`${i}`] = v;
    });
    return result;
  });
  return {
    ...product,
    sizes,
    specsColumns,
    specsRows,
  };
};

const mapProductToImages = (product) => {
  return product?.images?.length
    ? product.images.map((i) => ({
        path: i,
        progress: 100,
        completed: true,
        useOnCard: product.cardImages?.includes(i),
      }))
    : [];
};

const mapProductToPhotos = (product) => {
  return product?.photos?.length
    ? product.photos.map((i) => ({
        path: i,
        progress: 100,
        completed: true,
      }))
    : [];
};

function AddProduct({ db, storage, existingProduct, backCallback, successCallback }) {
  const [productCategories] = useAtom(productCategoriesAtom);
  const [form] = Form.useForm();
  const [isPreviewing, setIsPreviewing] = useState(false);
  const [pendingImages, setPendingImages] = useState(mapProductToImages(existingProduct));
  const [pendingPhotos, setPendingPhotos] = useState(mapProductToPhotos(existingProduct));
  const [imagesToDelete, setImagesToDelete] = useState([]);
  const [, setformValues] = useState([]);

  useEffect(() => {
    if (existingProduct) {
      const values = mapProductToForm(existingProduct);
      form.setFieldsValue(values);
      setformValues(values);
    }
  }, [form, existingProduct]);

  const isLoaded = productCategories?.length;
  const values = form.getFieldsValue();

  const completedImages = pendingImages?.filter((i) => i.progress === 100).map((i) => i.path);
  const cardImages = pendingImages?.filter((i) => i.progress === 100 && i.useOnCard !== false).map((i) => i.path);
  const completedPhotos = pendingPhotos?.filter((i) => i.progress === 100).map((i) => i.path);

  const product = mapFormToProduct(values, completedImages, cardImages, completedPhotos, imagesToDelete);

  const resetForm = () => {
    form.resetFields();
    setPendingImages([]);
    setPendingPhotos([]);
  };

  const del = (path) =>
    deleteObject(ref(storage, path))
      .then(() => console.log('Deleted ', path))
      .catch((err) => console.error('Failed to delete ', path, err));

  const deleteImageAndThumbs = (i) =>
    Promise.all([del(i.path), del(`thumbs128/${i.path}`), del(`thumbs256/${i.path}`), del(`thumbs1024/${i.path}`)]);

  const deleteImages = async () => {
    setPendingImages((oldImages) => oldImages.filter((j) => !imagesToDelete.some((i) => i.path === j.path)));
    setPendingPhotos((oldImages) => oldImages.filter((j) => !imagesToDelete.some((i) => i.path === j.path)));
    await Promise.all(imagesToDelete.map(deleteImageAndThumbs));
    setImagesToDelete([]);
  };

  const onSubmit = async () => {
    form.validateFields();
    if (
      !(
        values &&
        values.name &&
        values.category &&
        values.subCategory &&
        values.brand &&
        values.price &&
        (!values.sizes?.length || values.sizes.every((s) => s && s.sizeName && s.sizePrice)) &&
        values.description &&
        completedImages?.length &&
        cardImages?.length
      )
    ) {
      message.error('Fill out all required fields and add at least 1 image');
      return;
    }

    try {
      Object.keys(product).forEach((key) => {
        if (product[key] === undefined) delete product[key];
      });

      await deleteImages();
      if (existingProduct) {
        await updateDoc(doc(db, 'products', existingProduct.id), product);
        console.log('Document updated with ID: ', existingProduct.id, product);
        successCallback();
      } else {
        const docRef = await addDoc(collection(db, 'products'), product);
        console.log('Document written with ID: ', docRef.id, product);
        resetForm();
      }

      window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
      message.success('Product was saved successfully');
    } catch (err) {
      console.error('error saving product', err, product);
      message.error('There was an error saving the product');
    }
  };

  const uploadImage = useCallback(
    ({ file, path }) => {
      const storageRef = ref(storage, path);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const path = uploadTask.snapshot.ref._location.path_;
          const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          setPendingImages((oldImages) => {
            const oldImage = oldImages.find((i) => i.path === path);
            if (oldImage) {
              return oldImages.map((i) => (i.path === oldImage.path ? { path, progress } : i));
            } else {
              return [...oldImages, { path, progress }];
            }
          });
        },
        (error) => {
          alert(error);
          const path = uploadTask.snapshot.ref._location.path_;
          setPendingImages((oldImages) => {
            const oldImage = oldImages.find((i) => i.path === path);
            if (oldImage) {
              return oldImages.map((i) => (i.path === oldImage.path ? { path, error } : i));
            } else {
              return [...oldImages, { path, error }];
            }
          });
        },
        () => {
          // completed
          const path = uploadTask.snapshot.ref._location.path_;
          setTimeout(
            () =>
              setPendingImages((oldImages) => {
                const oldImage = oldImages.find((i) => i.path === path);
                if (oldImage) {
                  return oldImages.map((i) => (i.path === oldImage.path ? { ...i, completed: true } : i));
                } else {
                  return [...oldImages, { path, completed: true }];
                }
              }),
            5000
          );
        }
      );
    },
    [storage]
  );

  const uploadImages = (e) => {
    e.preventDefault();

    const folder = form.getFieldValue('name');
    if (!folder || !e.target?.files?.length) return;

    const newPendingImages = [];
    for (let i = 0; i < e.target.files.length; i++) {
      const file = e.target.files[i];
      const path = `products/${urlFormat(folder)}/images/${file.name}`;
      newPendingImages.push({ file, path, progress: -1 });
    }
    setPendingImages((oldImages) => [...oldImages, ...newPendingImages]);
  };

  useEffect(() => {
    const firstNotStarted = pendingImages.find((i) => i.progress === -1);
    if (firstNotStarted && !pendingImages.some((i) => i.progress > -1 && i.progress < 100)) {
      uploadImage(firstNotStarted);
    }
  }, [pendingImages, uploadImage]);

  const uploadPhoto = useCallback(
    ({ file, path }) => {
      const storageRef = ref(storage, path);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const path = uploadTask.snapshot.ref._location.path_;
          const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          setPendingPhotos((oldImages) => {
            const oldImage = oldImages.find((i) => i.path === path);
            if (oldImage) {
              return oldImages.map((i) => (i.path === oldImage.path ? { path, progress } : i));
            } else {
              return [...oldImages, { path, progress }];
            }
          });
        },
        (error) => {
          alert(error);
          const path = uploadTask.snapshot.ref._location.path_;
          setPendingPhotos((oldImages) => {
            const oldImage = oldImages.find((i) => i.path === path);
            if (oldImage) {
              return oldImages.map((i) => (i.path === oldImage.path ? { path, error } : i));
            } else {
              return [...oldImages, { path, error }];
            }
          });
        },
        () => {
          // completed
          const path = uploadTask.snapshot.ref._location.path_;
          setTimeout(
            () =>
              setPendingPhotos((oldImages) => {
                const oldImage = oldImages.find((i) => i.path === path);
                if (oldImage) {
                  return oldImages.map((i) => (i.path === oldImage.path ? { ...i, completed: true } : i));
                } else {
                  return [...oldImages, { path, completed: true }];
                }
              }),
            5000
          );
        }
      );
    },
    [storage]
  );

  const uploadPhotos = (e) => {
    e.preventDefault();

    const folder = form.getFieldValue('name');
    if (!folder || !e.target?.files?.length) return;

    const newPendingPhotos = [];
    for (let i = 0; i < e.target.files.length; i++) {
      const file = e.target.files[i];
      const path = `products/${urlFormat(folder)}/photos/${file.name}`;
      newPendingPhotos.push({ file, path, progress: -1 });
    }
    setPendingPhotos((oldImages) => [...oldImages, ...newPendingPhotos]);
  };

  useEffect(() => {
    const firstNotStarted = pendingPhotos.find((i) => i.progress === -1);
    if (firstNotStarted && !pendingPhotos.some((i) => i.progress > -1 && i.progress < 100)) {
      uploadPhoto(firstNotStarted);
    }
  }, [pendingPhotos, uploadPhoto]);

  function TopControls() {
    return (
      <div className="top">
        {backCallback && <Button onClick={backCallback}>Back</Button>}
        <div className="preview">
          <Switch
            checkedChildren="Preview"
            unCheckedChildren="Preview"
            checked={isPreviewing}
            onChange={(checked) => setIsPreviewing(checked)}
          />
        </div>
      </div>
    );
  }

  function ProductMainInfoForm() {
    return (
      <>
        <div className="small-fields">
          <Form.Item label="Product name" name="name" rules={[{ required: true, message: 'Required' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Brand" name="brand" rules={[{ required: true, message: 'Required' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Category" name="category" rules={[{ required: true, message: 'Required' }]}>
            <Select>
              {productCategories.map((c) => (
                <Select.Option key={c.name} value={c.name}>
                  {c.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Subcategory" name="subCategory" rules={[{ required: true, message: 'Required' }]}>
            <Select disabled={!form.getFieldValue('category')}>
              {productCategories
                .find((c) => c.name === form.getFieldValue('category'))
                ?.subCategories.map((s) => (
                  <Select.Option key={s} value={s}>
                    {s}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item label="Sub Heading" name="subTitle">
            <Input />
          </Form.Item>
          <Form.Item label="Info. Line" name="infoLine">
            <Input />
          </Form.Item>
          <Form.Item label="More Info. Link" name="moreInfoLink">
            <Input />
          </Form.Item>
        </div>
        <Form.Item label="Hide Product" name="hidden" valuePropName="checked">
          <Switch checkedChildren="Hidden" unCheckedChildren="Hidden" />
        </Form.Item>
      </>
    );
  }

  function ProductPriceForm() {
    return (
      <>
        <div className="small-fields">
          <Form.Item label="Default Price" name="price" rules={[{ required: true, message: 'Required' }]}>
            <Input />
          </Form.Item>
        </div>
        <Form.List name="sizes">
          {(fields, { add, remove }, { errors }) => (
            <div className="product-sizes">
              {fields.map(({ key, name, ...restField }) => (
                <Space key={key} style={{ display: 'flex' }} align="baseline">
                  <Form.Item {...restField} name={[name, 'sizeName']} rules={[{ required: true, message: 'Required' }]}>
                    <Input placeholder="Size name" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'sizePrice']}
                    rules={[{ required: true, message: 'Required' }]}
                  >
                    <Input placeholder="Price" />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button className="add-size-button" type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  Add a product size
                </Button>
              </Form.Item>
            </div>
          )}
        </Form.List>
        <Form.Item label="Sale Item" name="onSale" valuePropName="checked">
          <Switch checkedChildren="On Sale" unCheckedChildren="On Sale" />
        </Form.Item>
      </>
    );
  }

  const onDeleteImage = (image) => {
    setImagesToDelete((oldImages) => [...oldImages, image]);
  };

  const onUndoDeleteImage = (image) => {
    setImagesToDelete((oldImages) => oldImages.filter((o) => o.path !== image.path));
  };

  function ProductImageForm() {
    return (
      <div className="image-upload">
        <Form.Item label="Upload images">
          <Input type="file" accept="image/*" multiple onChange={uploadImages} disabled={!form.getFieldValue('name')} />
        </Form.Item>
        {!!pendingImages?.length && (
          <div className="pending-images">
            {pendingImages.map((i) => (
              <div
                className={`pending-image${imagesToDelete.some((j) => i.path === j.path) ? ' half-invisible' : ''}`}
                key={i.path}
              >
                {i.completed ? (
                  <img alt="" src={`${STORAGE_URL}thumbs128/${i.path}`} />
                ) : (
                  <Progress type="circle" percent={i.progress} width={40} />
                )}
                <div className="path">{nameFromPath(i.path)}</div>
                {i.progress === 100 && (
                  <>
                    <Switch
                      checked={i.useOnCard === false ? false : true}
                      checkedChildren="Use on card"
                      unCheckedChildren="Use on card"
                      defaultChecked
                      onChange={(checked) => {
                        setPendingImages((oldImages) =>
                          oldImages.map((o) => (o.path === i.path ? { ...o, useOnCard: checked } : o))
                        );
                      }}
                      disabled={imagesToDelete.some((j) => i.path === j.path)}
                    />
                    {imagesToDelete.some((j) => i.path === j.path) ? (
                      <Button className="delete-button undo" danger onClick={() => onUndoDeleteImage(i)}>
                        Undo
                      </Button>
                    ) : (
                      <Button className="delete-button" danger onClick={() => onDeleteImage(i)}>
                        Delete
                      </Button>
                    )}
                  </>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }

  function ProductOverviewForm() {
    return (
      <Form.Item label="Overview" name="description" rules={[{ required: true, message: 'Required' }]}>
        <Quill
          theme="snow"
          modules={{
            toolbar: [
              [{ font: [] }, { size: [] }],
              ['bold', 'italic', 'underline', 'strike'],
              ['direction', { align: [] }],
              [{ color: [] }, { background: [] }],
              [{ script: 'super' }, { script: 'sub' }],
              [{ list: 'ordered' }, { list: 'bullet' }],
              ['link', 'image', 'video'],
              ['clean'],
            ],
          }}
        />
      </Form.Item>
    );
  }

  function ProductFeaturesForm() {
    return (
      <Form.Item label="Features" name="features">
        <Quill
          theme="snow"
          modules={{
            toolbar: [
              [{ font: [] }, { size: [] }],
              ['bold', 'italic', 'underline', 'strike'],
              ['direction', { align: [] }],
              [{ color: [] }, { background: [] }],
              [{ script: 'super' }, { script: 'sub' }],
              [{ list: 'ordered' }, { list: 'bullet' }],
              ['link', 'image', 'video'],
              ['clean'],
            ],
          }}
        />
      </Form.Item>
    );
  }

  function ProductSpecificationsForm() {
    const columns = form.getFieldValue('specsColumns');
    return (
      <div className="specs">
        <Form.List name="specsColumns">
          {(fields, { add, remove }, { errors }) => (
            <div className="product-specs">
              <Space style={{ display: 'flex' }} align="baseline">
                {fields.map(({ key, name, ...restField }) => (
                  <Fragment key={key}>
                    <Form.Item
                      {...restField}
                      name={[name, 'columnName']}
                      rules={[{ required: true, message: 'Required' }]}
                    >
                      <Input placeholder="Column name" />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Fragment>
                ))}
              </Space>
              <Form.Item>
                <Button className="add-col-button" type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  Add a column
                </Button>
              </Form.Item>
            </div>
          )}
        </Form.List>
        {!!columns?.length && (
          <Form.List name="specsRows">
            {(fields, { add, remove }, { errors }) => (
              <div className="product-specs">
                {fields.map(({ key, name, ...restField }, i) => (
                  <Space key={key} style={{ display: 'flex' }} align="baseline">
                    {columns?.map((col, j) => (
                      <Form.Item
                        {...restField}
                        key={`${col?.columnName}${j}`}
                        name={[name, `${j}`]}
                        rules={[{ required: true, message: 'Required' }]}
                      >
                        <Input placeholder="" />
                      </Form.Item>
                    ))}
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button className="add-row-button" type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                    Add a row
                  </Button>
                </Form.Item>
              </div>
            )}
          </Form.List>
        )}
      </div>
    );
  }

  function ProductPhotosForm() {
    return (
      <div className="image-upload">
        <Form.Item label="Upload photos">
          <Input type="file" accept="image/*" multiple onChange={uploadPhotos} disabled={!form.getFieldValue('name')} />
        </Form.Item>
        {!!pendingPhotos?.length && (
          <div className="pending-images">
            {pendingPhotos.map((i) => (
              <div
                className={`pending-image${imagesToDelete.some((j) => i.path === j.path) ? ' half-invisible' : ''}`}
                key={i.path}
              >
                {i.completed ? (
                  <img alt="" src={`${STORAGE_URL}thumbs128/${i.path}`} />
                ) : (
                  <Progress type="circle" percent={i.progress} width={40} />
                )}
                <div className="path">{nameFromPath(i.path)}</div>
                {i.progress === 100 && (
                  <>
                    {imagesToDelete.some((j) => i.path === j.path) ? (
                      <Button className="delete-button undo" danger onClick={() => onUndoDeleteImage(i)}>
                        Undo
                      </Button>
                    ) : (
                      <Button className="delete-button" danger onClick={() => onDeleteImage(i)}>
                        Delete
                      </Button>
                    )}
                  </>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }

  function ProductVideosForm() {
    return (
      <Form.Item label="Youtube Link" name="videoLink">
        <Input />
      </Form.Item>
    );
  }

  function ProductForm() {
    return (
      <>
        <div className={isPreviewing ? 'invisible' : 'form'}>
          <Form name="add-product" autoComplete="off" form={form} onFieldsChange={(_, values) => setformValues(values)}>
            <Collapse defaultActiveKey={['main']} accordion>
              <Collapse.Panel
                header={existingProduct?.name || 'Main Information'}
                key="main"
                extra="Required"
                forceRender
              >
                {ProductMainInfoForm()}
              </Collapse.Panel>
              <Collapse.Panel header="Price & Sizes" key="price" extra="Required" forceRender>
                {ProductPriceForm()}
              </Collapse.Panel>
              <Collapse.Panel header="Product Images" key="images" extra="Required" forceRender>
                {ProductImageForm()}
              </Collapse.Panel>
              <Collapse.Panel header="Overview" key="overview" extra="Required" forceRender>
                {ProductOverviewForm()}
              </Collapse.Panel>
              <Collapse.Panel header="Features" key="features" forceRender>
                {ProductFeaturesForm()}
              </Collapse.Panel>
              <Collapse.Panel header="Specifications" key="specifications" forceRender>
                {ProductSpecificationsForm()}
              </Collapse.Panel>
              <Collapse.Panel header="Photos" key="photos" forceRender>
                {ProductPhotosForm()}
              </Collapse.Panel>
              <Collapse.Panel header="Videos" key="videos" forceRender>
                {ProductVideosForm()}
              </Collapse.Panel>
            </Collapse>
          </Form>
        </div>
        <Button className="submit-button" size="large" onClick={onSubmit}>
          Save Product
        </Button>
      </>
    );
  }

  return (
    <div className="add-product">
      {isLoaded && (
        <>
          {TopControls()}
          {isPreviewing && (
            <div className="previewing">
              <ProductDisplay product={product} />
            </div>
          )}
          {ProductForm()}
        </>
      )}
    </div>
  );
}

export default AddProduct;
