export const urlFormat = (str) =>
  str
    ?.toLowerCase()
    .replace(/[^a-z0-9 ]/g, '')
    .replace(/\s+/g, '-');

export const nameFromPath = (str) => {
  const split = str.split('/');
  return split.length < 2 ? str : split[split.length - 1];
};

export function delay(fn, ms) {
  let timer = 0;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(fn.bind(this, ...args), ms || 0);
  };
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function includesAll(str, arr) {
  const terms = arr.filter((t) => t !== 'and' && t.length > 2);
  return terms.every((item) => str?.toLowerCase().includes(item));
}
