import { Form, Input, Button } from 'antd';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

import './Login.css';

function Login() {
  const auth = getAuth();

  const onLogin = ({ email, password }) => {
    signInWithEmailAndPassword(auth, email, password).catch((error) => {
      console.error('Login error', error);
    });
  };

  return (
    <div className="login">
      <Form name="basic" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} onFinish={onLogin} autoComplete="off">
        <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Required' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Password" name="password" rules={[{ required: true, message: 'Required' }]}>
          <Input.Password />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Log in
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default Login;
