import { useEffect, useState } from 'react';
import { Button, Input, Form, Spin, message } from 'antd';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { useParams, useLocation } from 'react-router-dom';
import { useAtom } from 'jotai';
import axios from 'axios';

import { configAtom, userAtom } from '../atoms';
import { PAYMENTS_URL } from '../constants';

import './Payment.css';

function Payment({ type }) {
  const params = useParams();
  const location = useLocation();
  const analytics = getAnalytics();
  const [user] = useAtom(userAtom);
  const [config] = useAtom(configAtom);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const sessionId = location?.search && new URLSearchParams(location.search)?.get('checkout');
  const [session, setSession] = useState();
  const [isSessionLoading, setIsSessionLoading] = useState(false);

  useEffect(() => {
    logEvent(analytics, 'screen_view', { firebase_screen: 'Payment', firebase_screen_class: 'payment' });
    logEvent(analytics, 'view_payment');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getSession = async () => {
      if (sessionId && !session) {
        setIsSessionLoading(true);
        try {
          const sessionResponse = await axios.get(`${PAYMENTS_URL}checkout?session=${sessionId}`, {
            headers: { authorization: `Bearer ${await user?.getIdToken()}` },
          });
          setSession(sessionResponse?.data);
          logEvent(analytics, 'fetch_checkout');
        } catch (e) {
          console.error(e);
          logEvent(analytics, 'fetch_checkout_error');
          // TODO error state
        }
        setIsSessionLoading(false);
      }
    };
    getSession();
  }, [session, sessionId]);

  const onSubmitCode = async ({ code }) => {
    try {
      setIsSubmitting(true);
      const { data } = await axios.post(
        `${PAYMENTS_URL}code`,
        { code: params.code || code?.trim(), type },
        { headers: { authorization: `Bearer ${await user?.getIdToken()}` } }
      );
      if (data?.url) window.location.href = data.url;
      else throw new Error('No checkout URL');
    } catch (e) {
      message.error('Code is invalid or expired');
      logEvent(analytics, 'payment_code_error');
    }
    setIsSubmitting(false);
  };

  const pgContent = (
    <b>
      The Price of the P1/P2 course is ${config?.intermediatePGPrice || 1050} + gst. However if you sign up for the
      course within 7 days after completion of the Intro course, there is a ${config?.intermediatePGDiscount || 100}{' '}
      discount upon paying a ${config?.intermediatePGDeposit || 100} non-refundable deposit. Balance is due the first
      day you come out for the course. Once again, the course includes use of all equipment, glider, harness, helmet
      during the course.
    </b>
  );
  const hgContent = (
    <b>
      The Price of the More Airtime course is ${config?.intermediateHGPrice || 700} + gst. However if you sign up for
      the course within 7 days after completion of the Intro course, there is a ${config?.intermediateHGDiscount || 100}{' '}
      discount upon paying a ${config?.intermediateHGDeposit || 100} non-refundable deposit. Balance is due the first
      day you come out for the course. Once again, the course includes use of all equipment, glider, harness, helmet
      during the course.
    </b>
  );
  const genericContent = (
    <b>
      If you sign up for the course within 7 days after completion of the Intro course, there is a $
      {config?.intermediatePGDiscount || 100} discount upon paying a ${config?.intermediatePGDeposit || 100}{' '}
      non-refundable deposit. Balance is due the first day you come out for the course. Once again, the course includes
      use of all equipment, glider, harness, helmet during the course.
    </b>
  );
  let content;
  switch (type) {
    case 'pg':
      content = pgContent;
      break;
    case 'hg':
      content = hgContent;
      break;
    default:
      content = genericContent;
  }

  return (
    <div className="payment">
      <h1 className="page-title">Deposit Payment</h1>
      <div className="content">
        <div className="row">{content}</div>
        {!params.code && !sessionId && (
          <div className="row">To pay your deposit now, please enter the code given to you by the instructor:</div>
        )}
        <div className="row">
          {!sessionId && (
            <Form onFinish={onSubmitCode}>
              <div className="form-wrapper">
                {!params.code && (
                  <Form.Item name="code" rules={[{ required: true, message: 'Required' }]}>
                    <Input className="submit-code-input" />
                  </Form.Item>
                )}
                <div className="form-button-wrapper">
                  <Button htmlType="submit" className="submit-code-button" loading={isSubmitting}>
                    Pay my deposit
                  </Button>
                </div>
              </div>
            </Form>
          )}
          {!isSessionLoading && sessionId && session?.status !== 'complete' && (
            <div className="col">
              <h2>We're sorry, we were unable to confirm your payment</h2>
              <p>Please contact us at the phone number or email address below or try again</p>
            </div>
          )}
          {!isSessionLoading && sessionId && session?.status === 'complete' && (
            <div className="col">
              <h2>
                {session.custom_fields?.length && session.custom_fields[0].text?.value
                  ? `Thanks ${session.custom_fields[0].text.value}, we confirmed your payment!`
                  : 'We confirmed your payment!'}
              </h2>
              <p>
                You're all set and will receive a package of instructional materials shortly from your instructor at the
                email provided: <b>{session.customer_details?.email}</b>
              </p>
            </div>
          )}
          {isSessionLoading && (
            <div className="loading load-page">
              <Spin size="large" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Payment;
