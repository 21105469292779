import { useEffect } from 'react';
import { useAtom } from 'jotai';
import { Button, Carousel } from 'antd';
import { getAnalytics, logEvent } from 'firebase/analytics';

import { configAtom } from '../atoms';
import { STORAGE_URL } from '../constants';

import './PGLessons.css';

function CarouselItem(item) {
  return (
    <div className="carousel-item" key={item.image}>
      <div
        className="carousel-img"
        style={{
          backgroundImage: `url(${STORAGE_URL}${item.image})`,
        }}
      ></div>
    </div>
  );
}

const carouselItems = [
  { image: 'pg/a1.jpg' },
  { image: 'pg/a2.jpg' },
  { image: 'pg/a3.jpg' },
  { image: 'pg/a4.jpg' },
  { image: 'pg/a5.jpg' },
  { image: 'pg/a6.jpg' },
  { image: 'pg/a7.jpg' },
  { image: 'pg/a8.jpg' },
  { image: 'pg/a9.jpg' },
  { image: 'pg/a10.jpg' },
  { image: 'pg/a11.jpg' },
  { image: 'pg/a12.jpg' },
  { image: 'pg/a13.jpg' },
  { image: 'pg/a14.jpg' },
];

function PGLessons() {
  const analytics = getAnalytics();
  const [config] = useAtom(configAtom);

  useEffect(() => {
    logEvent(analytics, 'screen_view', { firebase_screen: 'PG Lessons', firebase_screen_class: 'pg-lessons' });
    logEvent(analytics, 'view_pg_lessons');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="pg-lessons">
      <h1 className="page-title">Paragliding Lessons</h1>
      <div className="content">
        <div className="row">
          <div className="col">
            <Carousel dotPosition="bottom" autoplay>
              {carouselItems?.map((item) => CarouselItem(item))}
            </Carousel>
          </div>
          <div className="col">
            <h1>INTRO PARAGLIDING</h1>
            <p>
              Muller Windsports Ltd is Western Canada's Largest Free Flying School and has been in operation since 1971.
              Our focus has always been creating knowledgable and safe pilots that will enjoy the sports of Paragliding
              and Hang Gliding for many years to come.
            </p>
            <p>
              Our Introductory courses include a Thursday evening ground school at the University of Calgary and
              Saturday Mornings outside in the City of Calgary to get time on the gliders. We use the Sunday Morning as
              a Bonus or Weather day if needed. Programming is through the{' '}
              <a
                href="https://www.ucalgary.ca/ActiveLiving/registration/Browse/All?filters=Outdoor-Centre"
                target="_blank"
                rel="noreferrer"
              >
                University of Calgary Outdoor Program Centre
              </a>
              .
            </p>
            <p>
              Feel the freedom of flying with the maneuverable nylon wing of a paraglider. Lessons with our experienced
              instructors include an evening lecture that covers equipment, flight theory and logistics. Then on the
              weekend you'll have 4 hours of practical flight training in a city park to learn how to launch, steer and
              fly the paraglider. You'll start on flat ground, and as your skill and comfort level increases, you'll
              gradually progress up the hill to higher take-off points.
            </p>
            <div className="dates-section">
              <h4>
                <b>2024 Dates for Introduction to Paragliding</b>
              </h4>
              <div className="dates">April 11, 13, and 14</div>
              <div className="dates">April 25, 27 and 28</div>
              <div className="dates">May 9, 11 and 12</div>
              <div className="dates">May 23, 25 and 26</div>
              <div className="dates">June 6, 8 and 9</div>
              <div className="dates">June 20, 22 and 23</div>
              <div className="dates">September 5, 7 and 8</div>
              <div className="dates">September 19, 21 and 22</div>
              <div className="dates">October 3, 5 and 6</div>
              <div className="dates">October 17, 19 and 20</div>
              {config?.showIntroPGLink ? (
                <a className="register-link" href={config?.introPGLink} target="_blank" rel="noreferrer">
                  <Button>Register for Intro Paragliding</Button>
                </a>
              ) : (
                <h3 className="no-register-link">Please check back at the start of the next season to register.</h3>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h1>
              BEGINNER P1/NOVICE P2
              <br />
              PARAGLIDING COURSE
            </h1>
            <p>
              <i>Pre-Requisite: UofC Intro Course</i>
            </p>
            <p>Our course for HPAC P1/P2 is run from April 1 to End of June and Mid- August to Mid October.</p>
            <p>
              The course is set for you to reach an ability to attain your High Flight in Golden, BC and Pass the HPAC
              P2 exam when all flight requirements are passed.
            </p>
          </div>
          <div className="col">
            <img className="static" alt="" src={`${STORAGE_URL}pg/0.webp`} />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <p>
              <b>The course consists of 3 Classroom Ground Schools involving:</b>
            </p>
            <ul>
              <div className="row subrow">
                <div className="col subcol">
                  <li>Paragliding Manual (Textbook)</li>
                  <li>Basic Aerodynamics</li>
                  <li>Paragliding Fundamentals</li>
                  <li>Launching & Landing Techniques</li>
                  <li>Glider Control</li>
                  <li>Ground Handling</li>
                  <li>Weather</li>
                </div>
                <div className="col subcol">
                  <li>Transport Canada Air Law</li>
                  <li>Rules of the Air</li>
                  <li>Advanced Maneuvers (SIV)</li>
                  <li>Reserve Parachute Technique</li>
                  <li>Equipment Checkovers and Packing</li>
                  <li>P1/P2 Exam Writing</li>
                </div>
              </div>
            </ul>
          </div>
          <div className="col">
            <p>
              <b>The course also consists of many hours of Ground Handling:</b>
            </p>
            <ul>
              <li>Mastering Forward Launches</li>
              <li>Mastering Reverse Launches in variable conditions</li>
            </ul>
            <p>
              We also have special gliders for Ground Handling in stronger winds so that allows for more availability
              for teaching sessions.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col img-col">
            <img className="static" alt="" src={`${STORAGE_URL}pg/cochranebanner.jpg`} />
          </div>
        </div>
        <div className="row">
          <div className="col wide">
            <p>
              During the flight sessions they will be done off our Private 300ft hill overlooking Cochrane Alberta when
              the weather allows. Most students get between 15 - 25 flights or more during the course. The amount of
              flights you get is based on your schedule and the weather. Also of course the quicker you hike the more
              flights you get…..
            </p>
            <p>
              Sessions are available Tuesday through Sunday Mornings and Tuesday through Saturday Evenings. We have an{' '}
              <a href={config?.onlineBookingLink} target="_blank" rel="noreferrer">
                online booking system
              </a>{' '}
              that opens on the days where the weather is conducive to Paragliding Training. Students log in at 4pm same
              day for the Evening Sessions and 4pm the day before for Morning Sessions. Example if you would like to
              come out on a Wednesday evening you would log into the system Wednesday at 4pm to see if slots are Open
              for Paragliding and if you would like to come Friday morning you would Log in Thursday at 4pm to see if
              slots are Open on Friday Morning.
            </p>
            <p>
              The end goal of the P1/P2 course is for those that feel they want to purchase equipment and go onto
              complete our High Flight Course in Golden BC. We include the High Flight Course with purchase of New or
              Used Equipment from our Shop. After several High Flights you will have met the HPAC P2 requirements for
              flying Solo without Full Time Instructor Supervision.
            </p>
            <p>
              <b>
                The Price of the Novice/Intermediate course is ${config?.intermediatePGPrice || 1050} + gst. However if
                you sign up for the course within 7 days after completion of the Intro course, there is a $
                {config?.intermediatePGDiscount || 100} discount upon paying a ${config?.intermediatePGDeposit || 100}{' '}
                non-refundable deposit. Balance is due the first day you come out for the course. Once again, the course
                includes use of all equipment, glider, harness, helmet during the course.
              </b>
            </p>
            <center>
              <a className="register-link" href="/pg-lessons/deposit">
                <Button>Pay your deposit for Intermediate Paragliding</Button>
              </a>
            </center>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <img className="static" alt="" src={`${STORAGE_URL}pg/pggolden6.jpg`} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PGLessons;
