import { Button, Carousel } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';

import { urlFormat } from '../util';
import { STORAGE_URL } from '../constants';
import { productCategoriesAtom } from '../atoms';

import './ProductButton.css';

function ProductButton({ product, hideSale }) {
  const navigate = useNavigate();
  const [productCategories] = useAtom(productCategoriesAtom);
  const category = productCategories.find((c) => c.name === product.category);

  const navigateTo = (url) => {
    navigate(url);
    window.scrollTo({ left: 0, top: 0 });
  };

  const onClick = () =>
    navigateTo(
      `/products/${urlFormat(category.shortName || category.name)}/${urlFormat(product.subCategory)}/${urlFormat(
        product.name
      )}`
    );

  const images = product.cardImages?.length ? product.cardImages : product.images;

  return (
    <Button key={product.name} className="product-button" onClick={onClick}>
      <div className="product-content">
        <div className="image">
          <Carousel effect="fade" dots={false} autoplay>
            {images.map((img) => (
              <div className="carousel-item" key={img}>
                <img alt={product.name} src={`${STORAGE_URL}thumbs256/${img}`} />
              </div>
            ))}
          </Carousel>
        </div>
        <div className="text">
          <div className="brand">{product.brand}</div>
          <h4 className="name">{product.name}</h4>
          <div className={product.onSale ? 'price on-sale' : 'price'}>
            {product.price === '-' ? 'Contact us for pricing' : product.price}
          </div>
        </div>
        {!hideSale && product.onSale && <div className="on-sale-banner">SALE</div>}
      </div>
    </Button>
  );
}

export default ProductButton;
